<template>
  <Slider
    v-if="products"
    :more-label="linkLabel"
    :more-href="link"
    :headline="headline"
    :headline-level="headlineLevel"
    :headline-anchor="headlineAnchor"
    :items="products"
    :subtext="subtext"
    slider-type="product"
  />
</template>

<script setup lang="ts">
  import { deserialize } from "@alchemy_cms/json_api"
  import type { SliderItem } from "~/components/Slider/index.vue"

  const props = defineProps<{ element: AlchemyElement }>()
  const { getIngredient, getValue } = useAlchemy()
  const { api } = useApi()

  interface SlideProduct {
    id?: string
    altText?: string
  }

  const slideProducts: SlideProduct[] = props.element.nestedElements
    .map((productSlide) => {
      const id = getValue<string>(productSlide, "product")
      const altText = getValue<string>(productSlide, "alt_text")
      return { id, altText }
    })
    // JS's compact to remove null values
    .filter((product) => product.id)

  const { data: products } = await useLazyAsyncData(
    `${props.element.name}-${props.element.id}`,
    () =>
      api("/jsonapi/product_thumbnails", {
        params: {
          fields: {
            product:
              "name,discontinued,imageUrl,imageSrcset,imageAlt,stars,urlPath,priceRange,newProduct,marketingTaxons,taxons",
          },
          filter: {
            id_in: slideProducts.map((prod) => prod.id),
          },
        },
      }),
    {
      transform: (data) => {
        type ReducedSolidusProductThumb = Pick<
          SolidusProduct,
          | "id"
          | "name"
          | "imageUrl"
          | "imageSrcset"
          | "imageAlt"
          | "stars"
          | "urlPath"
          | "discontinued"
          | "priceRange"
          | "newProduct"
          | "marketingTaxons"
        >

        const productData = deserialize(data) as ReducedSolidusProductThumb[]

        // Need to maintain the original order of elements
        return slideProducts.reduce((result: SliderItem[], slideProduct) => {
          const product = productData.find(
            (p) => p.id === slideProduct.id?.toString(),
          )
          const imageAlt = slideProduct.altText || product?.imageAlt

          if (product) {
            result.push({ ...product, imageAlt })
          }

          return result
        }, [])
      },
    },
  )

  const headline = getValue<string>(props.element, "headline")
  const headlineLevel =
    getIngredient<AlchemyIngredientHeadline>(props.element, "headline")
      ?.level || "3"
  const headlineAnchor = getIngredient<AlchemyIngredientHeadline>(
    props.element,
    "headline",
  )?.domId
  const link = getIngredient<AlchemyIngredientText>(
    props?.element,
    "link",
  )?.link
  const linkLabel = getValue<string>(props.element, "link")
  const subtext = getValue<string>(props.element, "subtext")
</script>
